/**
 * PillCheckbox
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class PillCheckbox
 * @param {object} props - Props
 */

import React, {useEffect, useState} from "react";
const availableSizes = ['sm', 'md', 'lg'];

const PillCheckbox = ({label, defaultChecked = false, onChange: handleChange, size = 'md', ...props}) => {
    const [checked, setChecked] = useState(defaultChecked);
    const onChange = event => {
        // update inner value
        setChecked(event.currentTarget.checked);
        // update outer value
        handleChange && handleChange(event);
    }

    useEffect(() => {
        setChecked(defaultChecked);
    }, [defaultChecked]);

    return (
        <label className={`checkbox-container ${availableSizes.includes(size) && `checkbox-container--size-${size}`}`}>
            <input
                name={label}
                type="checkbox"
                className="checkbox-container__input"
                onChange={onChange}
                checked={checked}
            />
            <span className="checkbox-container__label">{label}</span>
        </label>
    );
}

export default PillCheckbox;
