import React from "react"

import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import { graphql, useStaticQuery } from 'gatsby'
import CardArticlesList from "../app/modules/card-articles-list";
import Breadcrumbs from "../app/components/breadcrumbs";
import Hero from "../app/components/hero";
import Button from "../app/atoms/button"

const BlogPage = ({ data }) => {
    const { allBlogJson: articlesJson } = useStaticQuery(
        graphql`
            query {
                allBlogJson {
                    nodes {
                        lang
                        articles {
                            title
                            description
                            date
                            image
                            link
                            categories
                        }
                    }
                }
            }`
    );
    
    
    const articles = articlesJson?.nodes.find(n => n.lang==='it_IT')?.articles || [];
    

    const breadcrumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            label: 'Blog'
        }
    ];

    return (
        <Layout headerActiveItem={2}>
            <Seo title="Page two" />
            <div className="container">
                <div className="">
                    <Breadcrumbs homeLabel={'Home'} items={breadcrumbs} />
                    <Hero title="Blog" type="default">
                        Do you need <strong>tips</strong> to improve your laundry routine? Are you looking for some last-minute dinner inspiration?
                        Would you like to learn how to make the most of your <strong>smart appliances</strong>?
                        You’re in the right place! Our <strong>Blog</strong> is designed to answer all questions you
                        might have, give you precious suggestions on how to <strong>simplify your daily
                        life</strong> and provide you with smart solutions you didn’t know you needed.
                        Sit back and enjoy it!
                    </Hero>
                    <CardArticlesList className={'card-articles-list card-articles-list--blog'} articles={articles} />
                    <div style={{ textAlign: 'center', marginBottom: '40px' }}>
                        <Button label="Carica altri" color="blue" className="primary" />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogPage;
