/**
 * CardArticleFilters
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CardArticleFilters
 * @param {object} props - Props
 */

import React, {useState} from "react";
import PillCheckbox from "../../atoms/pill-checkbox";

const CardArticleFilters = () => {
    const [filters, setFilters] = useState({
        all: false,
        howTo: true,
        smartSolution: true,
        lifestyle: false,
        taste: false
    });

    return (
        <div className="filters-container">
            <span className="filters-container__label">Categorie:</span>
            <ul className="filters-container__list">
                <li className="filters-container__item">
                    <PillCheckbox label="Tutti" defaultChecked={filters.all} onChange={checked => setFilters({...filters, all: checked})}/>
                </li>
                <li>
                    <PillCheckbox label="How To" defaultChecked={filters.howTo} onChange={checked => setFilters({...filters, howTo: checked})}/>
                </li>
                <li>
                    <PillCheckbox label="Soluzioni Smart" defaultChecked={filters.smartSolution} onChange={checked => setFilters({...filters, smartSolution: checked})}/>
                </li>
                <li>
                    <PillCheckbox label="Lifestyle" defaultChecked={filters.lifestyle} onChange={checked => setFilters({...filters, lifestyle: checked})}/>
                </li>
                <li>
                    <PillCheckbox label="Taste" defaultChecked={filters.taste} onChange={checked => setFilters({...filters, taste: checked})}/>
                </li>
            </ul>
        </div>
    );
}

export default CardArticleFilters;
