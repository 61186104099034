/**
 * CardArticlesList
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passing data to them.
 * @class CardArticlesList
 * @param {object} props - Props
 */

import React, { useState } from "react";
import CardArticle from "../../components/card-article";
import CardArticleFilters from "../../components/card-article-filters";
import CardArticleSort from "../../components/card-article-sort";

const CardArticlesList = (
    {
        articles,
        highlight: defaultHighlight = true,
        highlightCount: defaultHighlightCount = 3,
        withControls: defaultWithControls = true,
        className,
        ...others
    }) => {

    const [highlight] = useState(defaultHighlight);
    const [highlightCount] = useState(defaultHighlightCount);

    return (
        <div className={`card-articles-list ${className ? className : ''}`}>
            <div className="card-articles-list__controls">
                {defaultWithControls && (
                    <>
                        <CardArticleFilters />
                        <CardArticleSort sortLabel={'Ordina per:'} />
                    </>
                )}
            </div>
            <div className="card-articles-list__items-highlight">
                {
                    (articles && highlight) && articles
                        .slice(0, highlightCount)
                        .map(({ description, image, date, link, categories, title }, idx) =>
                            <div key={idx} className={`card-articles-list__item`}>
                                <CardArticle
                                    title={title}
                                    preTitle={categories}
                                    date={date}
                                    description={description}
                                    image={image}
                                    link={link}
                                />
                            </div>
                        )
                }
            </div>
            <div className="card-articles-list__items">
                {
                    articles && articles
                        .map(({ description, image, date, link, categories, title }, idx) =>
                        (idx >= (highlight ? highlightCount : 0) ?
                            <div key={idx} className={`card-articles-list__item`}>
                                <CardArticle
                                    title={title}
                                    preTitle={categories}
                                    date={date}
                                    description={description}
                                    image={image}
                                    link={link}
                                />
                            </div> : null))
                }
            </div>
        </div>
    );
};

export default CardArticlesList;
